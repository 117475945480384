import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { addGoogleAnalyticsPageData } from '../../utils/googleAnalyticsEvents'

import styled from 'styled-components'

const TableData = styled.td`
  padding-bottom: 16px;
  padding-top: 16px;
`

const TableHeader = styled.th`
  width: 160px;
  @media (max-width: 768px) {
    width: 75px;
  }
`

const PrivacyPolicy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Location>
      {({ location }) => (
        <Layout location={location} title={siteTitle} lang={'ar'}>
          <h1 className="customH1">{'سياسة الخصوصية'}</h1>
          <SEO
            title="سياسة الخصوصية"
            lang="ar"
            canonicalUrl={`${process.env.GATSBY_BASE_URL}ar/privacy-policy/`}
          />
          <div className="small-print-page">
            <h2>السياسة</h2>

            <p>
              في هذه السياسة تشير عبارات "نحن" و"لنا" إلى الشرق للخدمات
              الإخبارية المحدودة في جايت فيلادج 11، الطابق الرابع، مركز دبي
              المالي العالمي، ص.ب. 507159 ، دبي، الإمارات العربية المتحدة.
              ولأغراض هذه السياسة وبموجب قوانين حماية البيانات سارية المفعول،
              فنحن "نتحكّم بالبيانات". أما الإشارة إلى "أنت" تعني مستخدم الموقع
              والإشارات إلى المستخدم أو المستخدمين تعني مستخدمي الموقع من
              الأفراد (بما فيهم أنت) أو المجموعات، على حسب السياق.
            </p>

            <p>
              تحكم هذه السياسة جمع معلومات المستخدمين المحتملين واستخدامها من
              قبلنا. نحن ملتزمون بحماية واحترام خصوصية المستخدمين بما يتماشى مع
              هذه السياسة ومتطلبات القوانين سارية المفعول. توضح هذه السياسة جميع
              أنواع المعلومات التي نجمعها وكيف نستخدم المعلومات الشخصية ومع من
              نشاركها وكيف نحميها والحقوق القانونية للمستخدمين في ما يتعلق
              بمعلوماتهم الشخصية.
            </p>

            <p>
              يرجى قراءة هذه السياسة بعناية. من خلال الاطلاع على موقعنا وتصفحه،
              أنت تؤكد أنك قرأت وفهمت وواقفت على هذه السياسة كاملةً. وفي حال لم
              توافق على الشروط الواردة في هذه السياسة، يجب ألا تستخدم الموقع.
            </p>

            <p>
              تغطي هذه السياسة كل المعلومات الشخصية التي نجمعها ونستخدمها. وفي
              هذه السياسة، تعني عبارة "البيانات الشخصية" أو "المعلومات الشخصية"
              المعلومات التي تسمح بالتعرّف على هويتك كفرد أو معرفتك بشكل مباشر
              أو غير مباشر. وقد تشمل هذه المعلومات اسمك وتاريخ ميلادك وجنسيتك
              والنوع الاجتماعي وعنوان البريد والبريد الإلكتروني ورقم الهاتف
              ومعلومات فنية عن الأجهزة التي تستخدمها للنفاذ إلى موقعنا.
            </p>

            <p>
              قد نعمد إلى تحديث هذه السياسة من وقت إلى آخر استجابة إلى متطلبات
              قانونية أو تنظيمية أو تشغيلية متغيرة. وسوف يتم تحديث الموقع لعكس
              هذه التغيرات. ويعتبر استمرارك في استخدام موقعنا و/أو خدماتنا بعد
              دخول هذه التعديلات حيّز التنفيذ قبولاً لهذه التغييرات.
            </p>

            <h2>جمع المعلومات</h2>

            <p>
              قد نجمع معلومات عنك بطرق مختلفة. قد تعطينا هذه المعلومات مباشرة
              مثلاً عند الإجابة على مراسلة منا أو ملء استمارات أو عبر الهاتف أو
              البريد الإلكتروني أو المراسلات المكتوبة. قد نحصل على هذه المعلومات
              عنك من مواقع أخرى نشغّلها أو من خدمات أخرى نقدمها. كما قد نجمع
              المعلومات عنك من مصادر عامة متوفرة بما فيها محتوى متوفر للعموم على
              مواقع التواصل الاجتماعي. قد نجمع أيضاً معلومات بشكل تلقائي
              باستخدام كوكيز أو تكنولوجيات أخرى لدى زيارتك الموقع. مثلاً:
            </p>

            <ul>
              <li>
                المعلومات الفنية تشمل عنوان بروتوكول الإنترنت (IP) المستخدم
                للاتصال بالإنترنت عبر جهاز الكومبيوتر الخاص بك ومعلومات تسجيل
                الدخول ونوع المتصفّح الذي تستخدمه ونسخته ومعرّف الجهاز وإعدادات
                الموقع والتوقيت وأنواع البرامج المساعدة للمتصفّح ونسخها ونظام
                التشغيل والمنصة وأوقات استجابة الصفحات وأخطاء التحميل
              </li>
              <li>
                معلومات عن زيارتك وتشمل نقرات الـURL (وصلة الإنترنت) إلى موقعنا
                وعليه ومنه
              </li>
              <li>
                مدة الزيارات إلى بعض الصفحات ومعلومات التفاعل على الصفحة (مثل
                الانتقال والنقر وتمرير الماوس) والأساليب المستخدمة للانتقال خارج
                الصفحة
              </li>
            </ul>

            <h2>كوكيز</h2>

            <p>
              يستخدم موقعنا كوكيز وتكنولوجيات شبيهة. والكوكي هو ملف صغير من
              الأحرف والأرقام يضعها الموقع على الكومبيوتر الخاص بك أو غيرها من
              الأجهزة التي تستخدمها في النفاذ إلى الموقع. تسمح هذه الكوكيز لنا
              بأن نفرّق بينك وبين مستخدمين آخرين للموقع. تقوم الكوكيز بوظائف
              عديدة مثل السماح لك بالتصفح والتنقل بين الصفحات بفعالية وتذكر
              الإعدادات المفضلة لديك وبشكل عام تحسين تجربة المستخدم. هي تضمن
              أيضاً أن الإعلانات التي تراها على الشبكة أقرب إلى اهتماماتك.
            </p>

            <p>
              لدى استخدامك موقعنا أنت توافقك على الكوكيز المشار إليها في
              السياسة. يرجى الاطلاع على "كيفية التحكّم بالكوكيز" أدناه للمزيد من
              المعلومات حول كيفية سحب موافقتك في أي وقت. يرجى العلم أن أطراف
              ثالثة (منهم مثلاً شبكات الإعلانات وموردي الخدمات الخارجية مثل
              خدمات تحليل الحركة على الإنترنت) قد تستخدم الكوكيز التي هي خارج
              سيطرتنا. على الأرجح أنها كوكيز للتحليل والأداء أو للاستهداف.
            </p>

            <table>
              <tr>
                <TableHeader>نوقع الكوكيز</TableHeader>
                <th>الوظيفية /الغرض</th>
              </tr>
              <tr>
                <td>الكوكيز الأساسية</td>
                <TableData>
                  تجمع المعلومات حول كيفية استخدامك للموقع مثلاً أية صفحات
                  تزورها أكثر من غيرها وإذا كنت تستلم رسائل عن الخطأ من بعض
                  الصفحات. هذه الكوكيز لا تجمع معلومات تسمح بالتعرّف على هويتك.
                  يتم تجميع المعلومات مع بعضها وبالتالي الحفاظ على سرية صاحبها.
                  وهي تستخدم فقط لتحسين عمل الموقع. لدى استخدام موقعنا، أنت
                  توافق على وضعنا لهذا النوع من الكوكيز على جهازك.
                </TableData>
              </tr>
              <tr>
                <td>كوكيز الوظيفية </td>
                <TableData>
                  تسمح للموقع بتذكر الخيارات التي قمت بها (مثل اللغة التي
                  اخترتها أو المنطقة التي أنت متواجد فيها) وتوفر خصائص معززة
                  مكيّفة أكثر مع شخصيتك. يمكن أن تستخدم أيضاً لتذكر التغيرات
                  التي أحدثتها على حجم النص أو الخط أو أجزاء أخرى من الصفحة التي
                  يمكن تكييفها. يمكن عدم الإفصاح عن هوية صاحب المعلومات التي
                  جمعتها هذه الكوكيز وهي غير قادرة على تعقّب حركة التصفّح على
                  المواقع التي تشغلها أطراف ثالثة. لدى استخدام موقعنا، أنت توافق
                  على وضعنا هذا النوع من الكوكيز على جهازك.
                </TableData>
              </tr>
              <tr>
                <td>كوكيز الإعلانات</td>
                <TableData>
                  تستخدم لوضع إعلانات تهمك أكثر. وأيضاً للحد من عدد المرات التي
                  ترى فيها إعلان وتساعد في قياس مدى فعالية حملة إعلانية. تتذكر
                  أيضاً إذا ما زرت موقع من قبل. أحياناً نتشارك معلومات عن نشاطات
                  التصفّح من دون اسم صاحبها مع معلنينا وشركائنا الإبداعيين.
                  يستخدموا هذه المعلومات للإعلان عن منتجات قد تهمك على مواقع
                  أخرى أو تساعدنا في وضع الحملات الإعلانية المستقبلية.
                </TableData>
              </tr>
            </table>

            <p>
              ثمة في المتصفح الخاص بك خاصية تسمح لك برفض بعض الكوكيز أو كلها.
              لكن إذا ما اخترت رفض الكوكيز قد لا تتمكن من الدخول إلى بعض أجزاء
              الموقع وقد تكون تجربة التصفح أقل متعة. إن لم تعدّل الإعدادات في
              المتصفح لرفض الكوكيز، سوف يصدر نظامنا كوكيز بالطريقة المبينة أعلاه
              عندما تلج الموقع. إذا رغبت بحذف أي من الكوكيز على جهازك يرجى
              الاطلاع على الإرشادات من مصنع المتصفح الخاص بك من خلال النقر على
              زر المساعدة في قائمة المتصفح.
            </p>

            <h2>معلوماتك الشخصية</h2>

            <p>
              قد نستخدم معلوماتك الشخصية للامتثال إلى المتطلبات القانونية أو
              التقدّم بالدعاوى القانونية أو لأغراض الدفاع. قد نعالج معلوماتك
              الشخصية أيضاً، كلما اقتضت الحاجة، للحفاظ على أمن وسلامة موقعنا
              مثلاً من أية نشاطات غير قانونية أو احتيال مثل الاعتداءات
              الإلكترونية. كما يتعين علينا الاحتفاظ بقائمة محدثة لمستخدمين (بما
              في ذلك بعض المعلومات الشخصية) طلبوا منا عدم الاتصال بهم، لكي لا
              يتم الاتصال بهم مرة جديدة عن غير قصد.
            </p>

            <p>
              قد نفصح عن بعض معلوماتك الشخصية إلى طرف ثالث يقوم بمراسلتك بشكل
              منتظم نيابة عنا حول خصائص أو منتجات أو خدمات أو فعاليات أو عروض
              خاصة، أو إذا كنا نفكر في بيع أو شراء أعمال أو أصول، في هذه الحالة،
              قد نفصح عن معلوماتك الشخصية إلى الشاري أو البائع المحتمل لهذه
              الأعمال أو الأصول، أو إذا ما قام طرف ثالث بحيازة كل أو الجزء
              الأكبر من أصولنا وفي هذه الحالة فكل المعلومات التي نمتلكها عنك سوف
              تكون من بين الأصول التي يتم نقلها. قد نفصح عن معلوماتك الشخصية لأي
              من شركاتنا التابعة أي فروعنا وشركاتنا القابضة وفروعها أينما كانت
              في العالم ولأي سبب كان.
            </p>

            <p>
              يتم حفظ كل المعلومات التي تزودنا بها عبر موقعنا في خوادم أساسية
              آمنة. اتخذنا الخطوات الضرورية لحماية نزاهة مراسلاتنا والبنية
              التحتية الحاسوبية لدينا. تم تضمين إجراءات أمنية في التصميم
              والتطبيق والتشغيل اليومي للموقع ويندرج ذلك ضمن التزامنا بضمان أمن
              المحتوى الإلكتروني والنقل الإلكتروني للمعلومات. رغم أننا نبذل
              قصارى جهدنا، للأسف فإنه لا يمكن تأمين نقل المعلومات عبر الإنترنت
              بالكامل. ورغم أن لدينا عمليات وإجراءات أمن صارمة، لا يمكن أن نضمن
              أمن البيانات التي يتم نقلها على موقعنا.
            </p>

            <p>
              قد يتضمن موقعنا روابط من وإلى مواقع أطراف ثالثة. يرجى العلم أنك
              إذا نقرت على رابط للانتقال إلى أي من هذه المواقع، فهذه الأخيرة سوف
              يكون لديها شروط استخدام وسياسات خاصة بها ونحن لا نتحمل أية مسؤولية
              عن تلك السياسات. يرجى الاطلاع على هذه السياسات قبل إعطاء أية
              معلومات خاصة على تلك المواقع.
            </p>

            <h2>حقوقك</h2>

            <p>
              النفاذ إلى المعلومات: يمكن أن تطلب النفاذ إلى المعلومات عنك التي
              بحوزتنا من خلال الاتصال بنا عبر بيانات الاتصال الموضحة أدناه.
              وعادة ما نزودك بملف المعلومات الخاصة بك في غضون 30 يوماً رغم أنه،
              أحياناً، يتعذر علينا اطلاعك على معلوماتك الشخصية التي بحوزتنا
              (مثلاً، قد لا يمكن السماح لك بالنفاذ إليها في حال كان ذلك يؤثر
              بشكل غير منطقي على خصوصية طرف آخر أو إذا كان اطلاعك عليها يشكل
              تهديداً جدياً على حياة أو صحة أو سلامة شخص آخر).
            </p>

            <p>
              اعتراض: يحق لك أن تعترض على قيامنا بمعالجة معلوماتك الشخصية. ويمكن
              أيضاً أن تطلب منا التوقف عن معالجة معلوماتك الشخصية لأغراض التسويق
              المباشر. بأي حال لن نرسل لك أية مراسلات تسويقية إلا إذا أعطيت
              موافقتك المسبقة ولكن إذا غيرت رأيك يمكن بسهولة أن توقف الاشتراك في
              مراسلاتنا التسويقية في أي وقت من خلال اتباع الإرشادات المتضمنة في
              المراسلات التسويقية تلك. يرجى العلم أنه إذا وافقت على الحصول على
              عروض من شركائنا فهم مسؤولون عن مراعاة حقوقك بما في ذلك حق التوقف
              عن استلام العروض منهم.
            </p>

            <h2>الاتصال بنا</h2>
            <p>
              إذا كانت لديك أية أسئلة أو استفسارات حول كيفية تعاملنا مع بياناتك
              الشخصية وترغب في الطلب منا التوقف عن استخدام معلوماتك الشخصية أو
              تود أن تطلب نسخة عن معلوماتك الشخصية التي بحوزتنا، يرجى الاتصال
              بنا عبر البريد الإلكتروني
              <a
                href="http://mailto: mydata@asharq.com"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                mydata@asharq.com
              </a>{' '}
              أو من خلال مراسلتنا (مع ذكر عنوانك البريدي) على العنوان: الشرق
              للخدمات الإخبارية المحدودة، قسم الشؤون القانونية، جايت فيلادج 11،
              الطابق الرابع، مركز دبي المالي العالمي، ص.ب. 507159، دبي، الإمارات
              العربية المتحدة.
            </p>
          </div>
        </Layout>
      )}
    </Location>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
